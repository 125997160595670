





















import Vue from 'vue';

// store
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';

// components
import DesktopAppContainer from '@/container/common/DesktopAppContainer.vue';
import StatsChannelsContainer from '@/container/stats/StatsChannelsContainer.vue';
import StatsContainer from '@/container/stats/StatsContainer.vue';
import SelectedUserStatsContainer from '@/container/stats/SelectedUserStatsContainer.vue';
import MobileAppContainer from '@/container/common/MobileAppContainer.vue';
import MobileStatsContainer from '@/container/stats/mobile/MobileStatsContainer.vue';

// service
import { isMobile } from '@/service/StyleService';

export default Vue.extend({
  name: 'StatsScene',

  components: {
    DesktopAppContainer,
    StatsChannelsContainer,
    StatsContainer,
    SelectedUserStatsContainer,
    MobileAppContainer,
    MobileStatsContainer,
  },

  computed: {
    isMobile() {
      return isMobile(window);
    },
  },
});
